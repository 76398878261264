import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Index = () => (
  <Layout>
    <SEO title="CalvinT.com" />
    <div className="front">
      <h1>Hi!</h1>
      <blockquote><em>The site you're looking at is a Gatsby experiement I built in a few hours. It's a static site that pulls content from my original Drupal site via the JSON API. It's not super pretty, but it is pretty snappy!  Feel free to browse through my sporadic ramblings.  Thanks for the visit!</em></blockquote>
      <p>I'm Calvin. Currently a Drupal Developer at <a href="https://chapterthree.com" target="_blank" rel="noopener noreferrer" >Chapter Three</a>.</p>
      <p>I taught myself to program on a TS1000 at the age of 8, before the internet existed. Now we have super computers in our pockets!</p>
      <p>I'm interested in the rate of technological advancement, it's influence on every industry and scientific discipline, and it's affects on people and culture.</p>
      <p>I mainly build web applications for mid to large size organizations and I've had the priviledge to work with some pretty great teams. I poke at everything from system administration, to frontend styling, and everything else up "the Stack". Drupal since 4.3, PHP, JS, SCSS, getting into React. I've dabbled with Swift, Python, C++, and many others.</p>
    </div>
  </Layout>
)

export default Index
